/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { init } from "_config/ApiConfig";
import ToggleVisibilityNavigation from "components/ToggleVisibility/ToggleVisibilityNavigation.js"
import SectionSearchGroup from "views/PresentationPage/Sections/SectionSearchGroup.js";
import SectionConect from "views/ConectPage/Sections/SectionConect.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

init();

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Main Page"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/smartphone.jpg")}
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.shadowForText}>
                Flex Light
                </h1>
                <h2 className={classNames(classes.title, classes.shadowForText)}>
                  L'inventaire facile !</h2>
                <h3 className={classNames(classes.title, classes.shadowForSmallText)}>Connectez-vous</h3>
               <ToggleVisibilityNavigation/>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <br /><br /><br /><br />
     <SectionConect />
      <SectionSearchGroup />
      <div id="SearchPart"></div>
      <Footer
        className={classes.footer}
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    FLEX LIGHT
                  </a>
                </ListItem>
               
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.block}
                  >
                    CGU
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , {" "} <a
                href="https://www.artwebprod.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                ARTWEB-PRODUCTION
              </a>{" "}
            </div>
          </div>
        }
      />
    </div>
  );
}
