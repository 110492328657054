
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";

const useStyles = makeStyles(styles);

    export default function Compteur(props) {
        const classes = useStyles();
        // const easeInOutQuad = (t, b, c, d) => {
        //     t /= d / 2;
        //     if (t < 1) return (c / 2) * t * t + b;
        //     t--;
        //     return (-c / 2) * (t * (t - 2) - 1) + b;
        //   };

       /* const smoothScrollTarget = (target) => {
            var targetScroll = document.getElementById(target);
            scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
          };
        
        
          const scrollGo = (element, to, duration) => {
            var start = element.scrollTop,
              change = to - start,
              currentTime = 0,
              increment = 20;
        
            var animateScroll = function () {
              currentTime += increment;
              var val = easeInOutQuad(currentTime, start, change, duration);
              element.scrollTop = val;
              if (currentTime < duration) {
                setTimeout(animateScroll, increment);
              }
            };
            animateScroll();
          };*/

          const [messageEmptyLocalization, resultEmptyLocalization] = useState([]);

      const { id_std } = props
  
      const [count1, setCount1] = useState(0)
      const [count2, setCount2] = useState(0)
      const [count3, setCount3] = useState(0)
      const [count4, setCount4] = useState(0)
      const [count5, setCount5] = useState(0)
      const [count6, setCount6] = useState(0)
      
      const [nivfin1Result, setNivfin1Result] = useState([]);
      const [nivfin2Result, setNivfin2Result] = useState([]);
      const [nivfin3Result, setNivfin3Result] = useState([]);
      const [nivfinResult, setNivfinResult] = useState([]);
  
      // let nivfin1Res;//celle que l on affiche
      // let nivfin2Res;//celle que l on affiche
      // let nivfin3Res;//celle que l on affiche
      // let nivfinRes;//celle que l on affiche
  
      //celle qu l on appelle si nivfinResult est vide []

      const nivfinResultOnLoad = function (id_std) {
        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/GetQteStd.php`, {
            id_std: id_std,
            idLocalization: sessionStorage.getItem("idLocalization"), 
            idLevel: sessionStorage.getItem("idLevel"), 
            idZon: sessionStorage.getItem("idZon"),
            id_pro:sessionStorage.getItem("getIdOwnerSelect"),
          })
            .then(res => {
             // console.log(res.data.query);

              setNivfin1Result(res.data.qteStdData.nivfin1);
              setNivfin2Result(res.data.qteStdData.nivfin2);
              setNivfin3Result(res.data.qteStdData.nivfin3);
              setNivfinResult(res.data.qteStdData.nivfinstock);
              return;           
            })
        } catch (error) { throw error; }
      }

      let nivfin1Res;//celle que l on affiche
      let nivfin2Res;//celle que l on affiche
      let nivfin3Res;//celle que l on affiche
      let nivfinRes;//celle que l on affiche
     
    /*  if (nivfin1Result.length === 0 ) {     
        nivfinResultOnLoad(id_std);
      }
      else {
        nivfin1Res = nivfin1Result;
      }
      if (nivfin2Result.length === 0 ) {     
        nivfinResultOnLoad(id_std);
      }
      else {
        nivfin2Res = nivfin2Result;
      }
      if (nivfin3Result.length === 0 ) {     
        nivfinResultOnLoad(id_std);
      }
      else {
        nivfin3Res = nivfin3Result;
      }
      if (nivfinResult.length === 0 ) {     
        nivfinResultOnLoad(id_std);
      }
      else {
        nivfinRes = nivfinResult;
      }
      */
      if (nivfinResult.length === 0 ) {     
        nivfinResultOnLoad(id_std);
      }
      else {
        
        nivfinRes = nivfinResult;
        nivfin1Res = nivfin1Result;
        nivfin2Res = nivfin2Result;
        nivfin3Res = nivfin3Result;
      }

     const handleClick = function (e) {
        e.preventDefault()
        setCount1(count1 => count1 + 1)
        setQuantityItem(id_std, 1, 1)
      }
      const handleClick2 = function (e) {
        e.preventDefault()
        setCount2(count2 => count2 - 1)
        setQuantityItem(id_std, 0, 1)
      }
      const handleClick3 = function (e) {
        e.preventDefault()
        setCount3(count3 => count3 - 1)
        setQuantityItem(id_std, 1, 2)
      }
      const handleClick4 = function (e) {
        e.preventDefault()
        setCount4(count4 => count4 - 1)
        setQuantityItem(id_std, 0, 2)
      }
      const handleClick5 = function (e) {
        e.preventDefault()
        setCount5(count5 => count5 - 1)
        setQuantityItem(id_std, 1, 3)
      }
      const handleClick6 = function (e) {
        e.preventDefault()
        setCount6(count6 => count6 - 1)
        setQuantityItem(id_std, 0, 3)
      }
      const setQuantityItem = async (id, qte, quality) => {
        
        if (sessionStorage.getItem("statut_clt") === 'modeAdmin'){

        if(sessionStorage.getItem("spotLocalization") && sessionStorage.getItem("spotLevel") && sessionStorage.getItem("spotZone") && id ){
        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/AddItemQte.php`, {
            id_std: id, 
            qte_std: qte, 
            qualityStd: quality, 
            idLocalization: sessionStorage.getItem("idLocalization"), 
            idLevel: sessionStorage.getItem("idLevel"), 
            idZon: sessionStorage.getItem("idZon"),
            id_pro:sessionStorage.getItem("getIdOwnerSelect"),

            // spotLocalization: sessionStorage.getItem("spotLocalization"), 
            // spotLevel: sessionStorage.getItem("spotLevel"), 
            // spotZone: sessionStorage.getItem("spotZone"),
            // id_pro:sessionStorage.getItem("getIdOwnerSelect"),
            
          })
            .then(res => {
              setNivfin1Result(res.data.itemCountData.nivfin1);
              setNivfin2Result(res.data.itemCountData.nivfin2);
              setNivfin3Result(res.data.itemCountData.nivfin3);
              setNivfinResult(res.data.itemCountData.nivfinstock);
              resultEmptyLocalization('');// A voir
              return;
            })
        } catch (error) { throw error; }
      }
      else{
        resultEmptyLocalization('Définissez la localisation');
       }
      }
      else{
        resultEmptyLocalization('Inventaire en consultation seulement');
       }
      };
  
      return <>
       <div className={classes.textCenter}>
              <br />
              <h6 className={classes.textWarning}>{messageEmptyLocalization}</h6>
            </div>
       <div className={classes.mlAuto}>
                        <span className={classNames(classes.price, classes.priceNew)}>
                          Total : {nivfinRes}
                        </span>
                      </div>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <span key={1}>
              <div className={classes.buttonGroup}>
              Bon : {nivfin1Res}
                <br />
                <Button
                  color="tumblr"
                  size="sm"
                  round
                  onClick={handleClick}>
                  <Add />
                </Button>
                <Button
                  color="tumblr"
                  size="sm"
                  round
                  onClick={handleClick2}>
                  <Remove />
                </Button>
              </div>
            </span>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <span key={2}>
              <div className={classes.buttonGroup}>
              Fonctionnel : {nivfin2Res}
                <br />
                <Button
                  color="info"
                  size="sm"
                  round
                  onClick={handleClick3}>
                  <Add />
                </Button>
                <Button
                  color="info"
                  size="sm"
                  round
                  onClick={handleClick4}>
                  <Remove />
                </Button>
              </div>
            </span>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <span key={3}>
              <div className={classes.buttonGroup}>
              Hs : {nivfin3Res}
                <br />
                <Button
                  color="linkedin"
                  size="sm"
                  round
                  onClick={handleClick5}>
                  <Add />
                </Button>
                <Button
                  color="linkedin"
                  size="sm"
                  round
                  onClick={handleClick6}>
                  <Remove />
                </Button>
              </div>
            </span>
          </GridItem>
        </GridContainer>
      </>
    }