/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import DefaultInput from "components/ToggleVisibility/DefaultInput.js";

import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionFabricantPage() {

  const getFabricantProduct = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetFabricantProduct.php`)
        .then(res => {
          //console.log(res.data.fabricantproductdata)
          setFabricantProduct(res.data.fabricantproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const [isFabricantProduct, setFabricantProduct] = useState([]);
  const [fabricantSelect, setFabricantSelect] = useState([]);

  sessionStorage.setItem("getFabricantSelect", fabricantSelect);


  const classes = useStyles();
  useEffect(() => {
    getFabricantProduct();
  }, []);

  return (
      <div className={classNames(classes.main, classes.mainRaised_)}>
        <div className={classes.container_}>
          <label>FABRICANT </label><ToggleVisibilityAddInput>
          <DefaultInput 
           valueString={'Fabricant'}
           />
           </ToggleVisibilityAddInput>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={fabricantSelect}
              onChange={event => setFabricantSelect(event.target.value)}
              inputProps={{
                name: "fabricantSelect",
                id: "fabricantSelect"
              }}
            >
              {isFabricantProduct.map((item) => (
                <MenuItem key={item.id_fbq}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item.id_fbq}
                >
                  {item.design_fbq}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
  );
}
