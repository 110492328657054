/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Email from "@material-ui/icons/Email";
// import Favorite from "@material-ui/icons/Favorite";
// import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import CustomInput from "components/CustomInput/CustomInput.js";

import logoutPageStyle from "assets/jss/material-kit-pro-react/views/logoutPageStyle.js";

import image from "assets/img/smartphone.jpg";

const useStyles = makeStyles(logoutPageStyle);



export default function LogoutPage() {

const [islogout, setlogout] = useState([]);

const getLogoutData = async (ids) => {
  try {
    Axios.defaults.withCredentials = true;
    Axios.get(`${ApiConfig.baseUrl}/Controls/GetLogout.php`)
      .then(res => {
       // console.log(res.data.logoutdata);
        setlogout(res.data.logoutdata);
        sessionStorage.clear();
        return;
      })
  } catch (error) { throw error; }
};
  
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    getLogoutData();
  }, []);

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="FLEXLIGHT"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Déconnecté(e)...</h4>
                  </CardHeader>
                  <CardBody signup>                   
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button simple color="info" size="lg" href="/">                   
                      Retour
                    </Button>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/"
                      className={classes.block}
                    >
                      FLEXLIGHT     
                    </a>
                  </ListItem>
                 
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.artwebprod.com/CGU_ARTWEB-PRODUCTION.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.block}
                    >
                      CGU
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , {" "}
                <a
                  href="https://www.artwebprod.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ARTWEB-PRODUCTION
                </a>{" "}
               
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
