/*eslint-disable*/
import React, { useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";

import { useHistory } from "react-router-dom";

import SectionGroupPage from "views/PresentationPage/Sections/SectionGroupCheckBox.js";

//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Search from "@material-ui/icons/Search";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { TextField } from '@material-ui/core/';

import formPageStyle from "assets/jss/material-kit-pro-react/views/presentationSections/formPageStyle.js";

const useStyles = makeStyles(formPageStyle);

export default function SectionSearchGroup() {
  
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //    return function cleanup() {};
  // });

  const history = useHistory();

  const classes = useStyles();

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };

  const smoothScrollClickTarget = (e, target) => {
    e.preventDefault();
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const [isNameParameter, setNameParameter] = useState({
    nameParameter: '',
  });
  const [message, noSearchResult] = useState([]);

  const onChangeValue = (e) => {
    setNameParameter({
      ...isNameParameter,
      [e.target.name]: e.target.value
    });
  }

  const getResearch = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/GetResearch.php`, {
        id_pro:sessionStorage.getItem("getIdOwnerSelect"),
        designation: isNameParameter.nameParameter,
        groupeChecked:sessionStorage.getItem("setNewChecked"),
        
      })
        .then(res => {
                      //console.log(res.data.query);

          sessionStorage.removeItem("setNewChecked");
          if (res.data.success === true) {
            //console.log(res.data.catalogueresearchdata);
            history.push("/catalogue-page");
          }
          else {
            history.push("/");
            noSearchResult('Aucun résultat, reformulez votre recherche');
            smoothScrollTarget("FormSearch");

            return;
          }


        })
    } catch (error) { throw error; }
  };
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
  return (
    <div>
      <ScrollAnimation animateIn="animate__fadeInUp">
        <div id="FormSearch" className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form} onSubmit={getResearch}>
                  <CardHeader
                    color="info"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Recherche</h4>
                  </CardHeader>

                  <CardBody signup>
                    <TextField className={classes.input}
                      type="text"
                      id="nameParameter"
                      name="nameParameter"
                      variant="standard"
                      onChange={onChangeValue}
                      label="Désignation..."
                      placeholder="Saisir le nom"
                      autoComplete="off"
                      required
                    />
                    <h6 className={classes.textWarning}>{message}</h6>
                  </CardBody>



                  <SectionGroupPage />
                 
                  <br /><br />

                  <div className={classes.textCenter}>
                    <Button href="/" color="info" round onClick={getResearch}>Lancer une recherche&nbsp;&nbsp;
                      <Search className={classes.icons} />
                    </Button>
                    <br /><br />
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </ScrollAnimation>
    </div>
  );
}
else
{
  return false;
}
}
