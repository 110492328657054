import Axios from 'axios';

const dev = false; //false

const localUrl = 'http://localhost/FlexLight';
const prodUrl = '';

const baseUrl = dev ? localUrl : prodUrl;

const baseUrlApi = `${baseUrl}`;
const baseUrlFile = '';

Axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error);
    if (error.response.status === 401) {
      console.log('unauthorized, logging out ...');
    }
    return Promise.reject(error.response);
  },
);

Axios.defaults.baseURL = baseUrlApi;
Axios.defaults.withCredentials = true;
const ApiConfig = {
  baseUrl,
  baseUrlApi,
  baseUrlFile,
};

export const init = () => {
  console.log('API INITIALIZED');
};

export default ApiConfig;
