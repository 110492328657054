/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons

import Assignment from "@material-ui/icons/Assignment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RoomIcon from '@material-ui/icons/Room';

// core components

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const classes = useStyles();
  //let activeClassName = "underline";
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
  return (
// voir isActive pour l'utiliser en js 51 mn video
<List className={classes.list + " " + classes.mlAuto}>
{/* <ListItem className={classes.listItem}>
<Link to="/conect-page" className={classes.navLink}>
<AccountCircle className={classes.dropdownIcons} />     
         CONNEXION
            </Link>
            </ListItem> */}
            <ListItem className={classes.listItem}>
<Link to="/localisation-page" className={classes.navLink}>
<RoomIcon className={classes.dropdownIcons} />     
         COMMENCER
            </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
<Link to="/catalogue-page" className={classes.navLink}>
<Assignment className={classes.dropdownIcons} />
              CATALOGUE
            </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
<Link to="/export-page" className={classes.navLink}>
<GetAppIcon className={classes.dropdownIcons} />
              EXPORT
            </Link>
            </ListItem>
      <ListItem className={classes.listItem}>
      <Link to="/account-page" className={classes.navLink}>
      <PersonAdd className={classes.dropdownIcons} />         
          INSCRIPTION
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
<Link to="/logout-page" className={classes.navLink}>
{/* <NavLink to="/logout-page" className={({ isActive }) => isActive ? activeClassName : undefined}> */}
{/* <NavLink to="/logout-page" className={({ isActive }) => isActive ? classes.navLinkOn : classes.navLink}> */}
{/* <NavLink to="/logout-page" className = {({isActive }) ? classes.navLinkOn : classes.navLink}> */}
<ExitToAppIcon className={classes.dropdownIcons} />
              DECONNEXION
            {/* </NavLink> */}
            </Link>
            </ListItem>
    </List>
  );
  }
  else if (sessionStorage.getItem("statut_clt") === 'modeUser') {
    return (
  // voir isActive pour l'utiliser en js 51 mn video
  <List className={classes.list + " " + classes.mlAuto}>
  {/* <ListItem className={classes.listItem}>
  <Link to="/conect-page" className={classes.navLink}>
  <AccountCircle className={classes.dropdownIcons} />     
           CONNEXION
              </Link>
              </ListItem> */}
              <ListItem className={classes.listItem}>
  <Link to="/localisation-page" className={classes.navLink}>
  <RoomIcon className={classes.dropdownIcons} />     
           COMMENCER
              </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
  <Link to="/catalogue-page" className={classes.navLink}>
  <Assignment className={classes.dropdownIcons} />
                CATALOGUE
              </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
  <Link to="/export-page" className={classes.navLink}>
  <GetAppIcon className={classes.dropdownIcons} />
                EXPORT
              </Link>
              </ListItem>
        {/* <ListItem className={classes.listItem}>
        <Link to="/account-page" className={classes.navLink}>
        <PersonAdd className={classes.dropdownIcons} />         
            INSCRIPTION
          </Link>
        </ListItem> */}
        <ListItem className={classes.listItem}>
  <Link to="/logout-page" className={classes.navLink}>
  {/* <NavLink to="/logout-page" className={({ isActive }) => isActive ? activeClassName : undefined}> */}
  {/* <NavLink to="/logout-page" className={({ isActive }) => isActive ? classes.navLinkOn : classes.navLink}> */}
  {/* <NavLink to="/logout-page" className = {({isActive }) ? classes.navLinkOn : classes.navLink}> */}
  <ExitToAppIcon className={classes.dropdownIcons} />
                DECONNEXION
              {/* </NavLink> */}
              </Link>
              </ListItem>
      </List>
    );
    }
  else{
    return (
      <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
      <Link to="/conect-page" className={classes.navLink}>
      <AccountCircle className={classes.dropdownIcons} />     
               CONNEXION
                  </Link>
                  </ListItem>  
          </List>
        );    
  }
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white"
  ])
};
