import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField } from '@material-ui/core/';

import Button from "components/CustomButtons/Button.js";
import SaveIcon from '@material-ui/icons/Save';

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

export default function Default(props) {

  const classes = useStyles();

  const { valueString } = props
  const [addproductInfo, setAddproductInfo] = useState({
    designationLabel: '',
  });
  const [messageAdd, resultAddProduct] = useState([]);

  const onChangeValue = (e) => {
    setAddproductInfo({
      ...addproductInfo,
      [e.target.name]: e.target.value
    });
  }
  const addToProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      //console.log(valueString)
      Axios.defaults.withCredentials = true;
      if (valueString === 'Groupe') {
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddGroupe.php`, {
          productDesignation: addproductInfo.designationLabel,
        })
          .then(res => {
            if (res.data.groupeinsertdata.success === true) {
              resultAddProduct('Information ajoutée');
              if (sessionStorage.getItem("setSelectedEnabled")) {
                sessionStorage.removeItem("setSelectedEnabled");
              }
              sessionStorage.setItem("setSelectedEnabled", res.data.groupeinsertdata.id_grp);
            }
            else {
              resultAddProduct('Saisie manquante');
            }
          })
      }

      else if (valueString === 'Couleur') {
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddColor.php`, {
          productDesignation: addproductInfo.designationLabel,
        })
          .then(res => {
            if (res.data.colorinsertdata.success === true) {
              resultAddProduct('Information ajoutée');
              if (sessionStorage.getItem("getColorSelect")) {
                sessionStorage.removeItem("getColorSelect");
              }
              sessionStorage.setItem("getColorSelect", res.data.colorinsertdata.id_col);
            }
            else {
              resultAddProduct('Saisie manquante');
            }
          })
      }
      else if (valueString === 'Finition') {
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddFinition.php`, {
          productDesignation: addproductInfo.designationLabel,
        })
          .then(res => {
            //console.log(res.data.finitioninsertdata)
            if (res.data.finitioninsertdata.success === true) {
              resultAddProduct('Information ajoutée');
              if (sessionStorage.getItem("getFinitionSelect")) {
                sessionStorage.removeItem("getFinitionSelect");
              }
              sessionStorage.setItem("getFinitionSelect", res.data.finitioninsertdata.id_fin);
            }
            else {
              resultAddProduct('Saisie manquante');
            }
          })
      }
      else if (valueString === 'Gamme') {
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddMarque.php`, {
          productDesignation: addproductInfo.designationLabel,
        })
          .then(res => {
            if (res.data.marqueinsertdata.success === true) {
              resultAddProduct('Information ajoutée');
              if (sessionStorage.getItem("getMarqueSelect")) {
                sessionStorage.removeItem("getMarqueSelect");
              }
              sessionStorage.setItem("getMarqueSelect", res.data.marqueinsertdata.id_mrq);
            }
            else {
              resultAddProduct('Saisie manquante');
            }
          })
      }
      else if (valueString === 'Materiau') {
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddMateriau.php`, {
          productDesignation: addproductInfo.designationLabel,
        })
          .then(res => {
            if (res.data.materiauinsertdata.success === true) {
              resultAddProduct('Information ajoutée');
              if (sessionStorage.getItem("getMateriauSelect")) {
                sessionStorage.removeItem("getMateriauSelect");
              }
              sessionStorage.setItem("getMateriauSelect", res.data.materiauinsertdata.id_mat);
            }
            else {
              resultAddProduct('Saisie manquante');
            }
          })
      }
      else if (valueString === 'Fournisseur') {
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddFournisseur.php`, {
          productDesignation: addproductInfo.designationLabel,
        })
          .then(res => {
            if (res.data.fournisseurinsertdata.success === true) {
              resultAddProduct('Information ajoutée');
              if (sessionStorage.getItem("getFournisseurSelect")) {
                sessionStorage.removeItem("getFournisseurSelect");
              }
              sessionStorage.setItem("getFournisseurSelect", res.data.fournisseurinsertdata.id_frn);
            }
            else {
              resultAddProduct('Saisie manquante');
            }
          })
      }
      else if (valueString === 'Fabricant') {
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddFabricant.php`, {
          productDesignation: addproductInfo.designationLabel,
        })
          .then(res => {
            if (res.data.fabricantinsertdata.success === true) {
              resultAddProduct('Information ajoutée');
              if (sessionStorage.getItem("getFabricantSelect")) {
                sessionStorage.removeItem("getFabricantSelect");
              }
              sessionStorage.setItem("getFabricantSelect", res.data.fabricantinsertdata.id_fbq);
            }
            else {
              resultAddProduct('Saisie manquante');
            }
          })
      }
      // else if (valueString === 'Proprietaire') {
      //   Axios.post(`${ApiConfig.baseUrl}/Controls/AddOwner.php`, {
      //     productDesignation: addproductInfo.designationLabel,
      //   })
      //     .then(res => {
      //       if (res.data.ownerinsertdata.success === true) {
      //         resultAddProduct('Information ajoutée');
      //         if (sessionStorage.getItem("getIdOwnerSelect")) {
      //           sessionStorage.removeItem("getIdOwnerSelect");
      //         }
      //         sessionStorage.setItem("getIdOwnerSelect", res.data.ownerinsertdata.id_pro);
      //         sessionStorage.setItem("getNameOwnerSelect", res.data.ownerinsertdata.nom_pro);
      //       }
      //       else {
      //         resultAddProduct('Saisie manquante');
      //       }
      //     })
      // }
    } catch (error) { throw error; }
  };

  //CONCATENATION
  return (
    <>
      <form onSubmit={addToProduct}>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <TextField className={classes.input}
              type="text"
              // fullWidth
              id="designationLabel"
              color="primary"
              name="designationLabel"
              variant="standard"
              onChange={onChangeValue}
              label="Définir"
              placeholder={valueString + " à saisir"}
              autoComplete="off"
              required
            />
          </GridItem>

          <GridItem xs={3} sm={3} md={3}>
            <Button color="info" size="sm" square="true"
              onClick={addToProduct}
            ><SaveIcon fontSize="large" />
            </Button>

          </GridItem>
        </GridContainer>
      </form><h6>{messageAdd}</h6>
    </>
  );
}