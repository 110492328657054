import React, { useEffect, useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
//import Remove from "@material-ui/icons/Remove";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles(styles);
/* React component where show/hide 
  functionality is implemented */

  export default function Default(props) {
    const classes = useStyles();
    const { id_value } = props

    const [messageEmptyLocalization, resultEmptyLocalization] = useState([]);


    const delInventoryItem = async (id_value) => {
        try {
          Axios.defaults.withCredentials = true;
          Axios.post(`${ApiConfig.baseUrl}/Controls/DelInventoryItem.php`, {
            id_item: id_value,
          })
            .then(res => {
              if (res.data.success === true) {
               resultEmptyLocalization('Inventaire numéro ' + res.data.id_inventaire + ' supprimé ');
                sessionStorage.removeItem("spotLocalization");
                sessionStorage.removeItem("spotLevel");
                sessionStorage.removeItem("spotZone");
                //sessionStorage.removeItem("inventorySelect");
    
              }
              else {
               resultEmptyLocalization('Erreur lors de la suppression ');
    
              }
              return;
            })
        } catch (error) { throw error; }
      };

    const handleClick = function (e) {
        e.preventDefault()
        resultEmptyLocalization('Inventaire numéro ' + id_value + ' supprimé ');
       delInventoryItem(id_value);
      }


      useEffect(() => {
        // window.scrollTo(0, 0);
        // document.body.scrollTop = 0;
        resultEmptyLocalization(" Supprimer l'inventaire définitivement ? ");

    
      }, []);
    
    return (
        <>
        <div className={classes.buttonGroup}>
               <span className={classes.priceNew}>{messageEmptyLocalization}</span>
              <Button
                color="danger"
                size="sm"
                round
                onClick={handleClick}>
               <DeleteForeverIcon />
              </Button>
             
            </div>
        </>
    );
}