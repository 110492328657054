/*eslint-disable*/
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

import { TextField } from '@material-ui/core/';

import SaveIcon from '@material-ui/icons/Save';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(profilePageStyle);

export default function SectionLocalisation() {

  const [addLocalisation, setaddLocalisationInfo] = useState({});

  let showSpotLocalization
  let showSpotLevel
  let showSpotZone

  //regarder quand on change de compte inventaire pour renitialiser ces valeurs
  if (!sessionStorage.getItem("spotLocalization")) {
    showSpotLocalization = "Immeuble ou Site";
  }
  else {
    showSpotLocalization = sessionStorage.getItem("spotLocalization");
  }

  if (!sessionStorage.getItem("spotLevel")) {
    showSpotLevel = "Etage ou Niveau";
  }
  else {
    showSpotLevel = sessionStorage.getItem("spotLevel");
  }

  if (!sessionStorage.getItem("spotZone")) {
    showSpotZone = "Bureau ou Zone";
  }
  else {
    showSpotZone = sessionStorage.getItem("spotZone");
  }

  const onChangeLocalization = (e) => {
    setaddLocalisationInfo({
      ...addLocalisation,
      [e.target.name]: e.target.value.toUpperCase()
    });
  }

  const classes = useStyles();

  const sampleLocation = useLocation();
  const history = useHistory();

  //console.log(sampleLocation.pathname);
  // console.log(window.location.href);
  const [messageUpdate, resultUpdateLocalization] = useState([]);
  const [message, resultLocalization] = useState([]);

  const addToLocalization = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      if (!sessionStorage.getItem("getIdOwnerSelect")) {
        resultUpdateLocalization('Commencer en renseignant un compte inventaire');
        return false;
      }

      if (sessionStorage.getItem("spotLocalization") && sessionStorage.getItem("spotLevel") && sessionStorage.getItem("spotZone")) {
        //111 pas de changement
        if (addLocalisation.spotLocalization === undefined && addLocalisation.spotLevel === undefined && addLocalisation.spotZone === undefined) {
          addLocalisation.spotLocalization=sessionStorage.getItem("spotLocalization")
          addLocalisation.spotLevel=sessionStorage.getItem("spotLevel")
          addLocalisation.spotZone=sessionStorage.getItem("spotZone")
          resultLocalization('Pas de changement enregistré');
        }
        else if (addLocalisation.spotLocalization === undefined && addLocalisation.spotLevel === undefined && addLocalisation.spotZone !== undefined) {
          addLocalisation.spotLocalization=sessionStorage.getItem("spotLocalization");
          addLocalisation.spotLevel=sessionStorage.getItem("spotLevel");
          resultUpdateLocalization('');
          resultLocalization('Changement de Bureau / Zone enregistré');
        }
        else if (addLocalisation.spotLocalization === undefined && addLocalisation.spotLevel !== undefined && addLocalisation.spotZone === undefined) {
          resultUpdateLocalization('Changement Etage / niveau, renseignez Bureau / Zone');
          resultLocalization('');
        }
        else if (addLocalisation.spotLocalization === undefined && addLocalisation.spotLevel !== undefined && addLocalisation.spotZone !== undefined) {
          addLocalisation.spotLocalization=sessionStorage.getItem("spotLocalization");        
          resultUpdateLocalization('');
          resultLocalization('Changement  Etage / niveau  et changement de Bureau / Zone enregistré ');
        }
        else if (addLocalisation.spotLocalization !== undefined && addLocalisation.spotLevel === undefined && addLocalisation.spotZone === undefined) {
          resultUpdateLocalization('Changement de Localisation, renseignez Etage / niveau  et Bureau / Zone');
          resultLocalization('');
        }
        else if (addLocalisation.spotLocalization !== undefined && addLocalisation.spotLevel === undefined && addLocalisation.spotZone !== undefined) {
          resultUpdateLocalization('changement de Localisation, renseignez Etage / niveau ');
          resultLocalization('');
        }
        else if (addLocalisation.spotLocalization !== undefined && addLocalisation.spotLevel !== undefined && addLocalisation.spotZone !== undefined) {
          resultUpdateLocalization('');
          resultLocalization('Changement de Localisation  et changement  Etage / niveau et changement de Bureau / Zone enregistré');
        }

      }

      //a l'initialisation

      else if (addLocalisation.spotLocalization === undefined) {
        resultUpdateLocalization('Renseignez la localisation');
        resultLocalization('');
      }
      else if (addLocalisation.spotLevel === undefined) {
        resultUpdateLocalization('Renseignez Etage / niveau  et  Bureau / Zone');
        resultLocalization('');
      }
      else if (addLocalisation.spotZone === undefined) {
        resultUpdateLocalization('Renseignez Bureau / Zone');
        resultLocalization('');
      }


      if (addLocalisation.spotLocalization !== undefined && addLocalisation.spotLevel !== undefined && addLocalisation.spotZone !== undefined) {      
        Axios.post(`${ApiConfig.baseUrl}/Controls/AddToLocalization.php`, {
          spotLocalization: addLocalisation.spotLocalization,
          spotLevel: addLocalisation.spotLevel,
          spotZone: addLocalisation.spotZone,
          id_pro: sessionStorage.getItem("getIdOwnerSelect"),
        })
          .then(res => {
            resultLocalization(res.data.mapdata.message);
            resultUpdateLocalization('');
            if (res.data.mapdata.success === true) {
              sessionStorage.setItem("spotLocalization", res.data.mapdata.spotLocalization);
              sessionStorage.setItem("spotLevel", res.data.mapdata.spotLevel);
              sessionStorage.setItem("spotZone", res.data.mapdata.spotZone);
              sessionStorage.setItem("idLocalization", res.data.mapdata.idLocalization);
              sessionStorage.setItem("idLevel", res.data.mapdata.idLevel);
              sessionStorage.setItem("idZon", res.data.mapdata.idZon);
              if(sampleLocation.pathname==="/catalogue-page"){
              history.push("/addProduct-page");
              }
            }
          })
      }

    } catch (error) { throw error; }
  };
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
    return (
      <div>
        <form className={classes.form} onSubmit={addToLocalization}>
          <GridContainer>
            <GridItem md={3} sm={3}>
              <TextField className={classes.input}
                type="text"
                fullWidth
                id="spotLocalization"
                color="primary"
                name="spotLocalization"
                variant="standard"
                onChange={onChangeLocalization}
                label={showSpotLocalization}
                placeholder="A saisir..."
                autoComplete="off"
                required
              />
            </GridItem>
            <GridItem md={3} sm={3}>
              <TextField className={classes.input}
                type="text"
                fullWidth
                id="spotLevel"
                name="spotLevel"
                color="primary"
                variant="standard"
                onChange={onChangeLocalization}
                label={showSpotLevel}
                placeholder="A saisir..."
                autoComplete="off"
                required
              />
            </GridItem>
            <GridItem md={3} sm={3}>
              <TextField className={classes.input}
                type="text"
                fullWidth
                id="spotZone"
                name="spotZone"
                color="primary"
                variant="standard"
                onChange={onChangeLocalization}
                label={showSpotZone}
                placeholder="A saisir..."
                autoComplete="off"
                required
              />
            </GridItem>
            <GridItem md={3} sm={3} className={classes.textCenter}>
              <Button color="info" size="sm" square="true" onClick={addToLocalization}><SaveIcon fontSize="large" />
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem
              xs={12}
              sm={12}
              md={6}
              className={classes.gridItem}
            >
              <div className={classes.textCenter}>
                <br />
                <h6 className={classes.textBlue}>{message}</h6>

                <h6 className={classes.textWarning}>{messageUpdate}</h6>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  } else return false;
}
