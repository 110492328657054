import React, { useEffect, useState } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
//import { Link } from "react-router-dom";

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
//import Remove from "@material-ui/icons/Remove";
//import Add from "@material-ui/icons/Add";
import RotateRightIcon from '@material-ui/icons/RotateRight';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";

import Accordion from "components/Accordion/Accordion.js";
import Default from "components/ToggleVisibility/Default.js";

import ToggleVisibility from "components/ToggleVisibility/ToggleVisibility.js";
import ToggleVisibilityUpdate from "components/ToggleVisibility/ToggleVisibilityUpdate.js";

import SectionLocalisation from "views/LocalisationPage/SectionLocalisation.js"

import Compteur from "views/CompteurPage/CompteurPage.js"

const useStyles = makeStyles(styles);

export default function SectionCatalogue() {
  /* composant avec une fonction autonome que vient remplir un div uniquement equivalent des declararation js avec Ajax, usage d'une prop uniquement*/

  const classes = useStyles();

  const [messageEmptyInventory, resultEmptyInventory] = useState([]);


  const [iscatalogue, setcatalogue] = useState([]);

  const [isitem, setitem] = useState([]);

  const [images, showPicture] = useState([]);

  const resetIndexData = () => {
    sessionStorage.setItem("ctr_index", 0);//seteur a 0 dans image gallery
  }
  /*CATALOGUE*/
  const getCatalogueData = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        id_pro: sessionStorage.getItem("getIdOwnerSelect"),
        spotLocalization: sessionStorage.getItem("spotLocalization"),
        spotLevel: sessionStorage.getItem("spotLevel"),
        spotZone: sessionStorage.getItem("spotZone"),
      })
        .then(res => {

          sessionStorage.setItem("idLocalization", res.data.id_loc);
          sessionStorage.setItem("idLevel", res.data.id_etg);
          sessionStorage.setItem("idZon", res.data.id_zon);
          if (res.data.success === true) {
            setcatalogue(res.data.cataloguedata);
          }
          else {
            resultEmptyInventory('Aucun produit en inventaire');

          }
          return;
        })
    } catch (error) { throw error; }
  };

  /*CHARGE les caracteristiques du produit*/
  const getItemData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetItem.php`, {
        id_std: id,
      })
        .then(res => {
          if (res.data.success === true) {
            setitem(res.data.itemdata);//data avec l'image en position 1
            getPicturesData(id);
            smoothScrollTarget("showItemId");
          }
          return;
        })
    } catch (error) { throw error; }
  };

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPictures.php`, {
        id_std: id,
      })
        .then(res => {
          if (sessionStorage.getItem("id_std")) {
            sessionStorage.removeItem("id_std");//TESTER L'EXISTENCE
          }
          sessionStorage.setItem("id_std", res.data.id_std);
          showPicture(res.data.picturedata);//alimente la gallery
          return;
        })
    } catch (error) { throw error; }
  };


  const getItemRotate = async (id, ctrIndex) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/RotatePicture.php`, {
        id_std: id, position: ctrIndex,
      })
        .then(res => {
         // console.log(res.data)
          getCatalogueData();//recharge le catalogue et prends la mise à jour de l'image
          getItemData(id);
          return;
        })
    } catch (error) { throw error; }
  };

  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({behavior: 'smooth' });
  };


  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };

  const smoothScrollClickTarget = (e, target) => {
    e.preventDefault();
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    getCatalogueData();//charge le catalogue par default
    getItemData(100000);//renvoi false

  }, []);

  return (
    <div id="showCatalogueId" className={classes.section}>
      <div className={classes.container}>
        <br />
        <br />
        <SectionLocalisation />
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItem}
          >
          </GridItem>
        </GridContainer>
        <h4 className={classes.cardTitle}>
          INVENTAIRE
        </h4>

        <ToggleVisibility>
          <Default />
        </ToggleVisibility>
        <div className={classes.textCenter}>
          <br />
          <h6 className={classes.textWarning}>{messageEmptyInventory}</h6>
        </div>
        <GridContainer>
          {iscatalogue.map((item) => (
            <GridItem xs={12} sm={6} md={4} key={item.id_std}>
              <div id={"cat_" + item.id_std} />
              <br /> <br /><br />
              <Card product plain>
                <CardHeader image plain>
                  <div className={classes.shadowForPicture}
                    style={{
                      backgroundImage: `url(${ApiConfig.baseUrl}/images/thumbs/${item.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      opacity: "1"
                    }}
                  >
                    <a href="#showItemId" onClick={() => { getItemData(item.id_std); resetIndexData(); }}>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </a>
                  </div>
                </CardHeader>
                <CardBody className={classes.textCenter} plain>
                  <h4 className={classes.cardTitle}>{item.libelle}</h4>
                  <p>Famille: {item.famille}</p>
                  <div>
                    <Compteur
                      id_std={item.id_std}
                    />
                  </div>
                </CardBody>
                <CardFooter plain>
                  <p className={classes.textJustify}>
                    {item.description}
                  </p>
                </CardFooter>
              </Card>
              {/* </div> */}
            </GridItem>
          ))}
        </GridContainer>
        {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
        <div id="showItemId"></div>
        <div className={classes.productPage}>
          {isitem.map((itemP) => (
            <div className={classes.ItemPage}>
              <div className={classNames(classes.section, classes.sectionGray)}>
                <div className={classes.container}>
                  <div className={classNames(classes.main, classes.mainRaised_)}>
                    <GridContainer>
                      <GridItem md={6} sm={6}>
                        <ImageGallery
                          showFullscreenButton={false}
                          showPlayButton={false}
                          startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                          items={images}
                          showThumbnails={true}
                          renderLeftNav={(onClick, disabled) => {
                            return (
                              <button
                                className='image-gallery-left-nav'
                                disabled={disabled}
                                onClick={onClick}
                              />
                            );
                          }}
                          renderRightNav={(onClick, disabled) => {
                            return (
                              <button
                                className='image-gallery-right-nav'
                                disabled={disabled}
                                onClick={onClick}

                              />
                            );
                          }}
                          renderCustomControls={(onClick) => {
                            if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                              return (
                                <Button
                                  color="transparent"
                                  size="sm"
                                  round
                                  onClick={() => getItemRotate(sessionStorage.getItem("id_std"), sessionStorage.getItem("ctr_index"))}
                                ><RotateRightIcon /></Button>
                              );
                            }
                          }}
                        />

                      </GridItem>
                      <GridItem md={6} sm={6}>
                        <h2 className={classes.title}>{itemP.libelle}</h2>
                        <h3 className={classes.mainPrice}>{itemP.groupe}</h3>
                        {/* <h3 className={classes.mainPrice}>{item.id_std}</h3> */}



                        <Accordion
                          active={0}
                          activeColor="info"
                          collapses={[
                            {
                              title: "Description",
                              content: (
                                <p> {itemP.description}
                                </p>
                              )
                            }
                          ]}
                        />
                        <Accordion
                          active={0}
                          activeColor="info"
                          collapses={[
                            {
                              title: "Informations",
                              content: (
                                <ul>
                                  <li>Hauteur en cm : {itemP.unitheight}</li>
                                  <li>Largeur en cm : {itemP.unitwidth}</li>
                                  <li>Profondeur en cm : {itemP.unitdeph}</li>
                                  <li>Volume en m3 : {itemP.unitvolume}</li>
                                </ul>
                              )
                            }
                          ]}
                        />
                        <Accordion
                          active={0}
                          activeColor="info"
                          collapses={[
                            {
                              title: "Complément",
                              content: (
                                <ul>
                                  <li>Couleur : {itemP.couleur}</li>
                                  <li>Materiau : {itemP.materiau}</li>
                                  <li>Finition : {itemP.finition}</li>
                                  <li>Fournisseur : {itemP.fournisseur}</li>
                                  <li>Gamme : {itemP.gamme}</li>
                                  <li>Fabricant : {itemP.fabricant}</li>
                                </ul>
                              )
                            }
                          ]}
                        />
                        <br /><br />


                        <GridContainer className={classes.pullRight}>

                          <ToggleVisibilityUpdate></ToggleVisibilityUpdate>
                          
                          <Button round color="info"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() => smoothScroll('cat_' + itemP.id_std)}
                          //onClick={e => smoothScrollClickTarget(e, "showCatalogueId")}
                          >
                            Catalogue
                          </Button>

                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </div>
            </div>
          )
          )}
        </div>
      </div>
    </div>
  );
}
