/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import DefaultInput from "components/ToggleVisibility/DefaultInput.js";

import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionMateriauPage() {

  const getMateriauProduct = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetMateriauProduct.php`)
        .then(res => {
          //console.log(res.data.materiauproductdata)
          setMateriauProduct(res.data.materiauproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const [isMateriauProduct, setMateriauProduct] = useState([]);
  const [materiauSelect, setMateriauSelect] = useState([]);

  sessionStorage.setItem("getMateriauSelect", materiauSelect);

  const classes = useStyles();
  useEffect(() => {
    getMateriauProduct();
  }, []);

  return (
      <div className={classNames(classes.main, classes.mainRaised_)}>
        <div className={classes.container_}>
          <label>MATERIAU </label><ToggleVisibilityAddInput>
          <DefaultInput 
           valueString={'Materiau'}
           />
           </ToggleVisibilityAddInput>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={materiauSelect}
              onChange={event => setMateriauSelect(event.target.value)}
              inputProps={{
                name: "materiauSelect",
                id: "materiauSelect"
              }}
            >
              {isMateriauProduct.map((item) => (
                <MenuItem key={item.id_mat}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item.id_mat}
                >
                  {item.design_mat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
  );
}
