/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Search from "@material-ui/icons/Search";

// @material-ui/icons

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionProductPage() {
  const [checked, setChecked] = useState([]);// achanger apres
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    //console.log(newChecked);
    sessionStorage.setItem("setNewChecked", newChecked);

  };

  const [isTypeProduct, setTypeProduct] = useState([]);

  const getTypeProduct = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetTypeProduct.php`)
        .then(res => {
          setTypeProduct(res.data.groupeproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  const classes = useStyles();
  useEffect(() => {
    getTypeProduct();
  }, []);

  return (
    <div>
        <div className={classes.container}>

          {isTypeProduct.map((item) => (
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
            >
              <div key={item.id_grp}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(item.id_grp)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.label, root: classes.labelRoot }}
                  label={item.design_grp}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
  );
}
